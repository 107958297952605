import Vue from "vue";
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en' //导入element ui中的默认英文配置
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'//导入element ui中的默认中文配置
import zh from "./zh"
import en from "./en"

Vue.use(VueI18n);   // 全局注册国际化包

const messages = {
  en: {
    ...enLocale,
    ...en//把导入的英文词条合并
  },
  zh: {
    ...zhLocale,
    ...zh//把导入的中文词条合并
  }
}

var lang = null;
function language() {
  var language = null;
  if (navigator.appName == 'Netscape') {
    language = navigator.language;
  }
  else {
    language = navigator.browserLanguage;
  }
  if (language.indexOf('en') > -1) {
    lang = "en"
  }
  else if (language.indexOf('zh') > -1) {
    lang = "zh"
  }
  else {
    lang = "en"
  }
  // console.log(lang)
}
language()

// 准备翻译的语言环境信息
const i18n = new VueI18n({
  locale: lang,   // 初始化语言
  messages,
});

export default i18n