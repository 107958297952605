<template>
  <div>
    <p class="p0 paddingSection"><span class="s0"><b>TERMS OF SERVICE</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">Effective Date: 5th Feb 2024</span></p>
    <p class="p1 paddingParagraph"><span class="s1"><b>JoyInn Ltd, here referred to as JoyInn, is an IT enabled company
          providing services and solutions for the Hospitality Industry. “JoyInn Frontdesk”, “JoyInn Console”, “JoyInn
          Admin APP” and “JoyInn Guest APP” provided by JoyInn Ltd are the names of four software owned by
          JoyInn.</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">These license terms are an agreement between JoyInn and User. Please
        read them and Our Privacy Policy. They apply to any software and its version, updates, supplements, Internet-based
        services, Offline components, and support services (if any) provided by JoyInn unless other terms accompany those
        items. If so, those terms apply. By subscribing and/or using the service, User accepts these terms. If User does
        not accept them, shall not use the service. If User is entering into this agreement on behalf of a company, then
        User represents that she/he has the authority to make purchase decisions for the company and all references to you
        shall refer to your company. As described below, using some features also operates as your consent to the
        transmission of certain standard computer information for Internet-based services.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">JoyInn provided by JoyInn Ltd is a Web-based Property Management
        System designed especially for hostels, apartments, small to mid-size hotels, motels and other properties of this
        kind.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">JoyInn reserves the right to change these terms at any time. We will
        notify you not less than ten (10) days prior to the effective date of any amendments to these terms and conditions
        and your continued use of the Service(s) following the effective date of any such amendment may rely upon Your
        acceptance of any such amendment. It is User’s obligation to ensure that one has read, understood and agree to the
        most recent terms and conditions.</span></p>

    <p class="p1 paddingSection"><span class="s1"><b>1. DEFINITIONS</b></span></p>
    <p class="p2 paddingParagraph"><b>“Confidential Information” </b>encompasses all
          information of legal, commercial, technical, administrative and other nature, pertaining to the JoyInn, its
          shareholders and their subsidiaries and affiliates, obtained in a written, oral or electronic form, disclosed to
          the User in connection with entering into and performing the Services, irrespective of whether it was made
          available to the User by the JoyInn or obtained by the User in a different manner. Confidential Information
          shall be in particular information about entering into the Terms and conditions agreement, its content, object
          and scope, as well as information about performing the Terms and conditions agreement;</span><span
          class="s1"><br>
        </span><span class="s2"><b>“Data”</b> means any data inputted by User into the Software;</span><span
          class="s1"><br>
        </span><span class="s2"><b>“Effective Date”</b> means the date upon which the Terms and Conditions take
          effect;</span><span class="s1"><br>
        </span><span class="s2"><b>“Property”</b> means any hostels, apartments, small to mid-size hotels, motels and
          other properties of this kind;</span><span class="s1"><br>
        </span><span class="s2"><b>“User”</b> means the entity which formally runs the Property for which the Service is
          going to be used;</span><span class="s1"><br>
        </span><span class="s2"><b>“License”</b> the rights to use the Services under conditions described in Terms and
          conditions agreement hereinafter;</span><span class="s1"><br>
        </span><span class="s2"><b>“Service”</b> means Frontdesk/Console/APP provided by JoyInn Ltd, and designed
          especially for hostels, apartments, small to mid-size hotels, motels and other properties of this kind which
          includes any software and its version, updates, supplements, Internet-based services, offline components, and
          support services (if any);</span><span class="s1"><br>
        </span><span class="s2"><b>“Terms and conditions agreement”</b> means this general terms and conditions governing
          the conditions under which the Service can be used be the User;</span><span class="s1"><br>
        </span><span class="s2"><b>“Website”</b> means www.joyinn.net .</span></p>
    <p class="p1 paddingSection"><span class="s1"><b>2. USE RIGHTS.  </b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">If User complies with this agreement, JoyInn grants to the User a
        non-exclusive, non-transferable, revocable right to use the Service for the User’s Property. User must only use
        the Service for its own lawful business purposes, in accordance with the Terms and conditions agreement.</span>
    </p>

    <p class="p1 paddingSection"><span class="s1"><b>3. USER OBLIGATIONS.</b></span></p>
    <ul class="ul1">
      <li class="li2"><span class="s3"></span><span class="s2">Compliance with Laws. User shall comply with all applicable
          laws in its use of and dealings with the Service including but not limited to, laws relating to privacy and data
          protection.</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">User warrants that it is the owner of and has all requisite
          legal rights to input, enter, store and process to the Service any and all Data and information that it inputs,
          enters, stores and processes to the Service.</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">Accuracy of Data. User shall alone be responsible for
          ensuring the accuracy of any and all Data that is inputted, entered, stored or processed by it to the
          Service.</span></li>
    </ul>
    <p class="p1 paddingSection"><span class="s1"><b>4. USER DATA &amp; CONTENT.</b></span></p>
    <ul class="ul1">
      <li class="li2"><span class="s3"></span><span class="s2">The User hereby grants to JoyInn a non-exclusive license to
          copy, reproduce, store, distribute, publish, export, adapt, edit and translate the User’s Data to the extent
          reasonably required for the performance of the Service and JoyInn’s rights under the Terms and conditions
          agreement. The User also grants to JoyInn the right to sub-license these rights to its hosting, connectivity and
          telecommunications service providers subject to any express restrictions elsewhere in the Terms and conditions
          agreement.</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">The User warrants to JoyInn that the User’s Data when used
          by JoyInn in accordance with the Agreement will not infringe the intellectual property rights, privacy rights or
          other rights of any person whose data is stored and processed in the Service.</span></li>
    </ul>
    <p class="p1 paddingSection"><span class="s1"><b>5. ACCESS CONDITIONS.</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">User decides who can access User’s Service, as well as, User’s Data.
        Therefore, User ensures that the access to the Service is provided only to select authorized persons, involved in
        User’s organization. By accepting these Terms and Conditions agreement User certifies that he/she understand that
        User takes responsibility for the actions of people (in scope of Service usage), who were authorized to access the
        Service. User certifies that he/she understands that people accessing User’s Service receive the access to User’s
        sensitive data, which is in User’s possession, and which protecting is User’s responsibility.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">If User gives feedback about the software to JoyInn, User gives to
        JoyInn, without charge, the right to use, share and commercialize your feedback in any way and for any purpose.
         User also gives to third parties, without charge, any patent rights necessary for their products, technologies
        and services to use or interface with any specific parts of a JoyInn software and service that includes the
        feedback.  User will not give feedback that is subject to a license that requires JoyInn to license its software
        or documentation to third parties because we include his/her feedback in them.  These rights survive this
        agreement.</span></p>
    <p class="p1 paddingSection"><span class="s1"><b>6. SCOPE OF LICENSE. </b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">The Service is licensed, not sold. The Service is protected by
        copyright and other intellectual property laws and treaties.  This agreement only gives User some rights to use
        the Service. JoyInn reserves all other rights. Unless applicable law gives User more rights despite this
        limitation, User may use the software only as expressly permitted in this agreement in specific User may view and
        browse the Service, enter and store Service’s data in User’s computer RAM memory and use the software solely for
        the purpose of running User’s entity.  In doing so, User must comply with technical limitations in the software
        that only allow User to use it in certain ways.  User may not:</span></p>
    <ul class="ul1">
      <li class="li2"><span class="s3"></span><span class="s2">work around the technical limitations in the
          software;</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">reverse engineer, decompile or disassemble the software or
          the potentially unwanted software definitions that work with the software, except and only to the extent that
          applicable law expressly permits, despite this limitation;</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">use the potentially unwanted software definitions
          separately from the software;</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">use the copy of the software for more Properties than
          specified in the license;</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">publish the software for others to copy;</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">distribute the software to any third party;</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">rent, lease or lend the software;</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">transfer the software or this agreement to any third party;
          or</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">use the software to provide commercial software hosting
          services.</span></li>
    </ul>
    <p class="p1 paddingSection"><span class="s1"><b>7. SUPPORT SERVICES.</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">JoyInn provides the support during week days from approx. 2am to 1pm
        GMT time. In the case of technical problems User may check the support solutions provided online by JoyInn or
        contact JoyInn Team at <a href="mailto:support@ufunnetwork.com"><span
            class="s4">support@ufunnetwork.com</span></a>.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">During weekends and national holidays JoyInn monitors the urgent
        cases, namely, loss of access to the application at <a href="mailto:support@ufunnetwork.com"><span
            class="s4">support@ufunnetwork.com</span></a>.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">While JoyInn intends to provide uninterrupted service it is possible
        that on occasion it may be unavailable to permit maintenance or other development activity to take place.</span>
    </p>
    <p class="p1 paddingParagraph"><span class="s1">If for any reason JoyInn has to interrupt the Service for longer
        periods than JoyInn would normally expect, we will use reasonable methods to instantly publish in advance details
        of such activity on the Website.</span></p>
    <p class="p1 paddingSection"><span class="s1"><b>8. DATA PROTECTION AND PRIVACY.</b></span></p>
    <p class="p1 paddingPart"><span class="s1">8.1 General.</span></p>
    <p class="p3 paddingParagraph"><span class="s1">To the extent that any content contains data that relates to
        individuals who can be identified either directly from that data by itself or from that data in conjunction with
        any other data or information held by or in the control of, or likely to come into the possession of,  User
        (“Personal Data”) User agrees that User shall for the purposes of any and all applicable data protection laws,
        including from 25 May 2018 the General Data Protection Regulation (“Applicable Data Protection Laws”) be the
        person or entity that determines the purposes and means of the processing of such Personal Data (the “Controller”)
        and that JoyInn shall not be the Controller in respect thereof. User further agrees that it is the responsibility
        of User to ensure that any and all Personal Data obtained and processed by it and inputted, entered, stored and
        processed by it to Service has been obtained and processed in accordance with all Applicable Data Protection
        Laws.</span></p>
    <p class="p1 paddingPart"><span class="s1">8.2 JoyInn.</span></p>
    <p class="p3 paddingParagraph"><span class="s1">User acknowledges that under certain Applicable Data Protection Laws
        the making available by JoyInn to User and the carrying out by JoyInn activities associated therewith may
        constitute JoyInn as a Processor (as defined under Applicable Data Protection Law) of User. To the extent that
        such activities involve processing of Personal Data on behalf of the User, the parties agree that:</span></p>
    <p class="p3 paddingPart"><span class="s2">(a) JoyInn shall process Personal Data in relation to User’s guests,
        including their name, email address, ID numbers, date of birth, nationality, other Personal Data provided by the
        User and details of their accommodation booking, on behalf of User in the context of providing the services under
        this Agreement, for the duration of the term of this Agreement. The obligations and rights of User shall be as set
        out in this Agreement;</span></p>
    <p class="p3 paddingParagraph"><span class="s2">(b) JoyInn will only process the Personal Data in accordance with the
        documented instructions of User, including with regard to transfers of the Personal Data to a third country and
        solely as strictly necessary for the performance of its obligations under this Agreement; <b>The Instruction at
          the time of entering into this Terms and conditions agreement is that JoyInn may only process the Personal Data
          supplied by User on its behalf with the purpose of delivering the Service.</b></span></p>
    <p class="p3 paddingParagraph"><span class="s2">(c) JoyInn shall ensure that the persons authorised by JoyInn to
        process the Personal Data are bound by appropriate confidentiality obligations;</span></p>
    <p class="p3 paddingParagraph"><span class="s2">(d) JoyInn shall implement such technical and organisational security
        measures as are required to comply with the data security obligations under Applicable Data Protection Law;</span>
    </p>
    <p class="p3 paddingParagraph"><span class="s2">(e) JoyInn is authorised to engage sub-processors in connection with
        the provision of the services under this Agreement. JoyInn shall inform User if it intends to engage a
        sub-processor or replace a sub-processor, and will provide User with an opportunity to object to such
        changes.</span></p>
    <p class="p3 paddingParagraph"><span class="s2">(f) where any sub-contractor of JoyInn will be processing the Personal
        Data on behalf of User, JoyInn shall ensure that a written contract exists between JoyInn and the sub-contractor
        containing clauses equivalent to those imposed on JoyInn in this Clause 9.1. In the event that any sub- processor
        fails to meet its data protection obligations JoyInn shall remain fully liable to User for the performance of the
        sub-processor’s obligations; User consents to JoyInn engaging third party sub-processors to process the User’s
        Data related to providing the Service based on the Terms and conditions agreement provided that: i. JoyInn
        maintains an up-to-date list of its sub-processors at joyinn.net/subprocessor which it shall update with details
        of any change in subprocessors prior to any such change. (ii) User may object to JoyInn’s appointment or
        replacement of a subprocessor prior to its appointment or replacement, provided such objection is based on
        reasonable grounds relating to data protection. In such event, JoyInn will either not appoint or replace the
        subprocessor or, if this is not possible, User may suspend or terminate the Service.</span></p>
    <p class="p3 paddingParagraph"><span class="s2">(g) JoyInn shall, taking into account the nature of the processing,
        assist User by implementing appropriate technical and organisational measures (insofar as this is possible) to
        assist User to comply with requests from data subjects to exercise their rights under Data Protection Law [and any
        such assistance shall be at the cost of User];</span></p>
    <p class="p3 paddingParagraph"><span class="s2">(h) JoyInn shall assist User in ensuring compliance with its
        obligations in respect of security of personal data, data protection impact assessments and prior consultation
        requirements under Data Protection Law [and any such assistance shall be at the cost of User];</span></p>
    <p class="p3 paddingParagraph"><span class="s2">(i) JoyInn shall: (i) at the choice of User made in writing within 14
        days from service termination date i.e. license expiration, delete or return the Personal Data to User; and (ii)
        delete all existing copies of such personal data unless EU law or the laws of an EU Member State require storage
        of the personal data [and any such return or deletion of data shall be at the cost of User];</span></p>
    <p class="p3 paddingParagraph"><span class="s2">(j) JoyInn shall: (i) make available to User all information necessary
        to demonstrate compliance with the obligations laid down in this Clause 8.2; and (ii) allow for and assist with
        audits, including inspections, conducted by User or another auditor mandated by User, in order to ensure
        compliance with the obligations laid down in this Clause 8.2. [JoyInn shall be entitled to charge the User any
        costs incurred in connection with compliance with the obligations at (i) and (ii) above and work performed at the
        request of the User]. For the purposes of demonstrating compliance with the data security obligations under Data
        Protection Law, User agrees that it shall be sufficient for JoyInn to provide evidence of adherence by JoyInn to
        an approved code of conduct or an approved certification mechanism;</span></p>
    <p class="p3 paddingParagraph"><span class="s2">(k) JoyInn shall [promptly/immediately] inform the User in the event
        that it receives an instruction that in its opinion would contravene Applicable Data Protection Law; and</span>
    </p>
    <p class="p3 paddingParagraph"><span class="s2">(l) taking into account the nature of the processing and the
        information available to JoyInn, JoyInn shall notify User without undue delay after becoming aware of any breach
        of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or
        access to, the Personal Data that is transmitted, stored or otherwise processed by JoyInn under this Agreement,
        and shall provide User with such reasonable co-operation and assistance as may be required to mitigate against the
        effects of, and comply with any reporting obligations which may apply in respect of, any such breach.</span></p>
    <p class="p1 paddingPart"><span class="s1">8.3 Data Subject Access Requests.</span></p>
    <p class="p3 paddingParagraph"><span class="s1">Without prejudice to the obligation to provide assistance under Clause
        8.2(g), User shall be responsible for complying with any and all requests that it may receive from data subjects
        under any Applicable Data Protection Laws to obtain access to, have corrected, erased or blocked any Personal Data
        relating to such data subjects which is held on Service software i.e. HostelSystem or JoyInn.</span></p>
    <p class="p1 paddingPart"><span class="s1">8.4 User Obligations.</span></p>
    <p class="p3 paddingParagraph"><span class="s1">User acknowledges that other than JoyInn’s obligations pursuant to
        Clause 9.1 above, User shall be responsible for implementing all other technical and organisational security
        measures required under Applicable Data Protection Laws in relation to its use of the Service, and the processing
        by it of any Personal Data on the Service.</span></p>
    <p class="p1 paddingSection"><span class="s1"><b>9. ACKNOWLEDGEMENTS.</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">9.1 Whilst JoyInn </span><span class="s5">intends</span><span
        class="s1"> to provide uninterrupted and bug free Service, JoyInn cannot guarantee uninterrupted or error free
        performance or that bugs will never occur as reasonably this is not possible given the nature of the software,
        internet and world wide web. User expressly acknowledges and agrees that JoyInn shall have no liability for
        non-performance or failure of functionality of the Service especially where same may be due to third party
        telecommunications equipment and services, the Content or any data, software, programs, operating systems,
        computers and telecommunications equipment used by User. Additionally, it does not take responsibility for the
        problems related to Channels unresponsiveness, communication or Content problems, as well as problems related to
        misconfiguration or misunderstanding on the system functionality.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">9.2 JoyInn reserves the right to update, change its website address as
        well as email, contact details. Such change does not constitute the change of the Terms and conditions agreement.
        JoyInn must notify User about such change.</span></p>
    <p class="p1 paddingSection"><span class="s1"><b>10. DISCLAIMER OF WARRANTIES.</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">The Service is licensed “as-is”. You bear the risk of using it. JoyInn
        gives no warranties, guarantees or conditions. Without limiting the foregoing, JoyInn does not warrant that the
        Service will meet your requirements or that it will be suitable for User’s purposes. To avoid doubt, all implied
        conditions or warranties are excluded in so far as is permitted by law, including (without limitation) warranties
        of merchantability, fitness for purpose, title and non-infringement.</span></p>
    <p class="p1 paddingSection"><span class="s1"><b>11. LIMITATION ON AND EXCLUSION OF DAMAGES.</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">JoyInn’s liability under this agreement is limited. This limitation
        applies especially to:</span></p>
    <ul class="ul1">
      <li class="li2"><span class="s3"></span><span class="s2">anything related to the Service, software, services,
          content (including code) on third party Internet sites, or third party programs, and</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">claims for breach of contract, breach of warranty,
          guarantee or condition, strict liability, negligence, or other tort to the extent permitted by applicable
          law.</span></li>
    </ul>
    <p class="p1 paddingParagraph"><span class="s1">It also applies even if JoyInn knew or should have known about the
        possibility of the damages.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">JoyInn shall not be liable or responsible for:</span></p>
    <ul class="ul1">
      <li class="li2"><span class="s3"></span><span class="s2">business decisions made on the basis of the information
          available or stored in the software</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">any damage or loss to your data protection and software
          which you use to access the software</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">damage or loss whatsoever suffered by you arising form or
          in any way connected with using or misuse of anti-virus tools</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">entering into Service untrue or false data or malfunction
          of the hardware, software or your Internet connection</span></li>
    </ul>
    <p class="p1 paddingSection"><span class="s1"><b>12. CONFIDENTIALITY</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">Unless the relevant party has the prior written consent of the other
        or unless required to do so by law:</span></p>
    <ul class="ul1">
      <li class="li2"><span class="s3"></span><span class="s2">Each party will preserve the confidentiality of all
          Confidential Information of the other obtained in connection with these Terms. Neither party will, without the
          prior written consent of the other, disclose or make any Confidential Information available to any person, or
          use the same for its own benefit, other than as contemplated by these Terms.</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">Each party’s obligations under this clause will survive
          termination of these Terms.</span></li>
    </ul>
    <p class="p1 paddingParagraph"><span class="s1">This does not apply to disclosure of Confidential Information in the
        following circumstances:</span></p>
    <p class="p1 paddingParagraph"><span class="s1">a) When the requirement to disclose arises from legal provisions,
        enforceable court judgement or a decision of another entitled authority. In such case, the User shall immediately
        notify the JoyInn thereof in writing (before Confidential Information is disclosed);</span></p>
    <p class="p1 paddingParagraph"><span class="s1">b) In connection with a dispute, discrepancy or court proceedings
        between the Parties encompassing Confidential Information – with the understanding that the User shall take
        appropriate measures to restrict the scope of disclosure of Confidential Information to purposes of such
        proceedings;</span></p>
    <p class="p1 paddingParagraph"><span class="s1">c) Information, which has become public domain without the
        Contractor’s involvement.</span></p>
    <p class="p1 paddingSection"><span class="s1"><b>13. PRIVACY POLICY</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">JoyInn maintains a Privacy Policy published on its Website that sets
        out the parties’ obligations in respect of data. User should read that policy at JoyInn website since you will be
        taken to have accepted it when you accept these Terms.</span></p>
    <p class="p1 paddingSection"><span class="s1"><b>14. TERM &amp; TERMINATION</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">a) The Terms and conditions agreement come into force upon the
        Effective Date.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">b) This Agreement shall automatically terminate on license expiry if
        new license or termination (for whatever reason) of the agreement between JoyInn and User.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">c) User can terminate the Agreement any time by sending the statement
        to <a href="mailto:support@ufunnetwork.com"><span class="s4">support@ufunnetwork.com</span></a>. The termination
        is effective at the last day of the month when the termination was received by JoyInn. There will be no refund for
        the remaining prepaid period of the month when the termination was announced. For the remaining prepaid period
        after the month when the termination was announced JoyInn will refund the License Fee with the deduction of the
        money transfer fees.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">d) JoyInn may terminate the contract and suspend for any definite or
        indefinite period of time User’s Access to the Service, if User:</span></p>
    <ul class="ul1">
      <li class="li2"><span class="s3"></span><span class="s2">breaches any of these Terms and does not remedy the breach
          within 7 days after receiving notice of the breach if the breach is capable of being remedied;</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">breaches any of these Terms and the breach is not capable
          of being remedied; or</span></li>
      <li class="li2"><span class="s3"></span><span class="s2">the paying subscriber goes into liquidation or has a
          receiver or manager appointed of any of its assets or becomes insolvent, or makes any arrangement with its
          creditors, or becomes subject to any similar insolvency event in any jurisdiction,</span></li>
    </ul>
    <p class="p1 paddingParagraph"><span class="s1">does not purchase the license within due date, ergo, User does not
        have a valid license.</span></p>
    <p class="p1 paddingSection"><span class="s1"><b>17. LEGAL EFFECT.</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">This agreement describes certain legal rights. If any part or
        provision of these Terms is invalid, unenforceable or in conflict with the law, that part or provision is replaced
        with a provision which, as far as possible, accomplishes the original purpose of that part or provision. The
        remainder of this Agreement will be binding on the parties.</span></p>
    <p class="p1 paddingSection"><span class="s1"><b>18. ENTIRE AGREEMENT.</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">This agreement and any amendments to it, and the terms for
        supplements, updates, Internet-based services and support services are the entire agreement for the software and
        support services.</span></p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>p.p0 {
  margin: 0.0px 50.0px 0.0px 50.0px;
  font: 24.0px Arial;
  color: #535353;
  -webkit-text-stroke: #535353;
  background-color: #ffffff
}

p.p1 {
  margin: 0.0px 50.0px 0.0px 50.0px;
  font: 16.0px Arial;
  color: #535353;
  -webkit-text-stroke: #535353;
  background-color: #ffffff
}

p.p2 {
  margin: 0.0px 50.0px 0.0px 50.0px;
  font: 16.0px Arial;
  color: #535353;
  -webkit-text-stroke: #535353
}

p.p3 {
  margin: 0.0px 50.0px 0.0px 50.0px;
  font: 14.0px Arial;
  color: #535353;
  -webkit-text-stroke: #535353
}

p.paddingParagraph {
  padding-top: 10px
}

p.paddingPart {
  padding-top: 20px;
  color: #000000;
}

p.paddingSection {
  padding-top: 45px;
  color: #000000;
}

li.li2 {
  margin: 5.0px 0.0px 0.0px 0.0px;
  font: 16.0px Arial;
  color: #535353;
  -webkit-text-stroke: #535353
}

span.s0 {
  line-height: 24px;
  font-kerning: none
}

span.s1 {
  line-height: 24px;
  font-kerning: none
}

span.s2 {
  line-height: 24px;
  font-kerning: none;
  background-color: #ffffff
}

span.s3 {
  line-height: 24px;
  background-color: #ffffff;
  -webkit-text-stroke: 0px #000000
}

span.s4 {
  line-height: 24px;
  font-kerning: none;
  color: #278fee;
  -webkit-text-stroke: 0px #278fee
}

span.s5 {
  line-height: 24px;
  text-decoration: underline;
  font-kerning: none
}

ul.ul1 {
  margin: 10.0px 50.0px 0.0px 50.0px;
  list-style-type: disc
}</style>