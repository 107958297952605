import Vue from 'vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/components/Css/theme.scss"
import App from './App.vue'
import i18n from '@/components/lang'

Vue.config.productionTip = false
// Vue.use(ElementUI);
Vue.use(ElementUI,{
  i18n:(key,value)=>i18n.t(key,value)
})

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
