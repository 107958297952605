<template>
    <el-menu id="sidebar" :default-active="$route.path" background-color="#ffffff" text-color="#000000"
        active-text-color="#ffffff" :router="true">
        <el-menu-item index="introduce">
            <span slot="title">{{ $t('main.介绍') }}</span>
        </el-menu-item>
        <el-menu-item index="install">
            <span slot="title">{{ $t('main.安装指南') }}</span>
        </el-menu-item>
        <el-menu-item index="quick">
            <span slot="title">{{ $t('main.快速开店') }}</span>
        </el-menu-item>
        <el-menu-item index="operate">
            <span slot="title">{{ $t('main.日常操作') }}</span>
        </el-menu-item>
        <el-menu-item index="yunying">
            <span slot="title">{{ $t('main.运营管理') }}</span>
        </el-menu-item><el-menu-item index="question">
            <span slot="title">{{ $t('main.常见问题') }}</span>
        </el-menu-item>
    </el-menu>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.el-menu {
    border-right: none;
    height: 100%;
}
#sidebar {
    // padding-top: 1.82291666vw;
    padding-right: 0.83333333vw;

    span {
        padding-left: 3.38541666vw;
        font-size: 0.9375vw;
    }

    .text {
        padding-left: 4.94791666vw;
        font-size: 0.9375vw;
    }

    .el-submenu__title {
        height: 2.39583333vw !important;
        line-height: 2.39583333vw !important;

        i {
            font-size: 1.14583333vw;
        }

        .el-submenu__icon-arrow {
            top: 44%;
        }
    }

    .el-submenu__title:hover {
        background-color: #efefef !important;
        border-radius: 0.52083333vw;
    }

    .el-menu-item {
        height: 2.39583333vw !important;
        line-height: 2.39583333vw !important;
    }

    .el-menu-item:hover {
        background-color: #efefef !important;
        border-radius: 0.52083333vw;
    }

    .is-active {
        color: #000000 !important;
        background-color: #efefef !important;
        border-radius: 0.52083333vw;
    }
}
</style>