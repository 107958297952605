<template>
  <div>
    <p class="p0 paddingSection"><span class="s0"><b>PRIVACY AND COOKIE POLICY</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">Effective Date: 5th Feb 2024</span></p>
    </br>
    <p class="p1 paddingParagraph"><span class="s1">We take your data protection seriously. We process personal data and
        therefore we have adopted this privacy policy, which informs you of how your personal data is processed.</span>
    </p>
    <p class="p1 paddingParagraph"><span class="s1">If you wish to contact us regarding our processing of personal data,
        you can do that by e-mail to <a href="mailto:support@ufunnetwork.com"><span
            class="s2">support@ufunnetwork.com</span></a>.</span></p>

    <p class="p1 paddingSection"><span class="s1"><b>1. Processing of personal data</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">Personal data is every kind of information which in some way can be
        assigned to you.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">As a customer the following personal data is collected: Name,
        initials, contact information and payment information.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">As a supplier and business partner the following personal data is
        collected: Name, initials, contact information and payment information.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">When you make use of our website we collect and process different
        kinds of kind of information. This happens for instance by ordinary access to content, if you register as a user,
        seminar participant or a subscriber, additional use of services or purchases through the website.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">Normally we collect and process the following type of data: a unique
        ID and technical information about your computer, tablet or mobile phone, your IP-address, geographical location,
        as well as which pages you click (interests).</span></p>

    <p class="p1 paddingPart"><span class="s1">1.1 Recruitment policy</span></p>
    <p class="p3 paddingParagraph"><span class="s1">When receiving a job application and relevant appendices, the materiel
        will be read by the relevant manager with the purpose of answering and assessing the content in relation to a
        defined job.</span></p>
    <p class="p3 paddingParagraph"><span class="s1">Job applications and CV’s are shared internally with relevant persons
        in the recruitment process and will not be disclosed to anyone outside the company.</span></p>
    <p class="p3 paddingParagraph"><span class="s1">Job applications and CV’s are saved until the right candidate is found
        and the recruitment process has been completed – then it will be deleted. For unsolicited applications, the
        application and CV will be saved for a maximum of 6 months after which it will be deleted.</span></p>
    <p class="p3 paddingParagraph"><span class="s1">If the application and CV are saved for more than 6 months, special
        consent of the candidate is obtained.</span></p>

    <p class="p1 paddingPart"><span class="s1">1.2 Safety</span></p>
    <p class="p3 paddingParagraph"><span class="s1">We have taken the necessary technical and organizational measures to
        prevent that your data accidentally or illegally gets deleted, released, lost, impaired or gets to the knowledge
        of any unauthorized persons, abused or in any way processed against the legislation.</span></p>

    <p class="p1 paddingSection"><span class="s1"><b>2. Purpose</b></span></p>

    <p class="p1 paddingPart"><span class="s1">2.1 Personal data about users is collected for the following
        purposes:</span></p>
    <ul class="ul1">
      <li class="li5"><span class="s3"></span><span class="s1">Use of queue and appointment management system</span></li>
    </ul>

    <p class="p1 paddingPart"><span class="s1">2.2 Personal data about customers, suppliers and business partners is
        collected for the following purposes:</span></p>
    <ul class="ul1">
      <li class="li5"><span class="s3"></span><span class="s1">Use of queue and appointment management system</span></li>
      <li class="li5"><span class="s3"></span><span class="s1">Administration of your relation with us</span></li>
      <li class="li5"><span class="s3"></span><span class="s1">Meet your agreements with us</span></li>
      <li class="li5"><span class="s3"></span><span class="s1">Processing purchases/services/invoicing</span></li>
    </ul>
    <p class="p1 paddingParagraph"><span class="s1">The data collected on our website is used to optimize our service and
        content.</span></p>

    <p class="p1 paddingPart"><span class="s1">2.3 Data minimization</span></p>
    <p class="p1 paddingParagraph"><span class="s1">We only collect, process and store the personal data necessary to
        fulfill our purpose. Furthermore, it may be determined by law, what type of data it is necessary to collect and
        store in connection with our business practices. The type and the extent of the personal data we process can be
        determined by the need to comply with a contract or any other legal obligation.</span></p>

    <p class="p1 paddingPart"><span class="s1">2.4 Data is updated</span></p>
    <p class="p1 paddingParagraph"><span class="s1">Since our service depends on the fact that your data is correct and up
        to date, we ask you to inform us about relevant changes in your personal data. You can make use of the contact
        information provided above to inform us about your changes, then we will make sure to update your data. If we
        notice that any data is incorrect, we will update the data and inform you about this.</span></p>

    <p class="p1 paddingPart"><span class="s1">2.5 Storage period</span></p>
    <p class="p1 paddingParagraph"><span class="s1">The data is stored in the time span which is allowed according to the
        legislation, and we will delete it when it is no longer necessary. The time span depends on the data’s character
        and the basis for the storage. According to this, it is not possible to state a general time span for when data is
        deleted.</span></p>


    <p class="p1 paddingPart"><span class="s1">2.6 Consent</span></p>
    <p class="p1 paddingParagraph"><span class="s1">Your consent to receiving our newsletter is voluntary, and you can
        withdraw it by contacting us at any time. Use the contact information above, if you want further
        information.</span></p>


    <p class="p1 paddingPart"><span class="s1">2.7 Passing on data</span></p>
    <p class="p1 paddingParagraph"><span class="s1">We use a number of third parties for storage and processing of data,
        including suppliers of IT-support, IT-hosting, text message providers and various business IT-solutions. They only
        process data on our behalf and may not use it for their own purposes. In relevant cases data is given to banks,
        debt collection, etc.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">We will only pass on data such as name, email and so on for other
        purposes, if you give your consent.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">We only make use of data processors in EU or in countries, which can
        protect data properly.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">Data concerning your website use is passed on to third party to that
        extent that the data is known. You can find out the third parties we are dealing with in the section “Use of
        cookies” below.</span></p>

    <p class="p1 paddingSection"><span class="s1"><b>3. Use of cookies</b></span></p>
    <p class="p1 paddingParagraph"><span class="s1">When you visit our website, information about you, which is used to
        adjust and improve our content and increase the value of the ads shown, is collected. If you do not want
        information about you to be collected, you should delete your cookies and refrain from visiting the website. You
        can see a list of what information is collected, their purpose and what third parties can access them
        below.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">Cookies are stored on your computer, mobile phone or similar with the
        purpose of recognizing it, remember settings, make statistics and focus the ads. Cookies cannot contain damaging
        content, such as virus.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">If you delete or block cookies ads will maybe become less relevant to
        you and occur more often. Furthermore, you risk that the website does not work optimally and that some content
        cannot be accessed.</span></p>

    <p class="p1 paddingPart"><span class="s1">3.1 What is a cookie?</span></p>
    <p class="p1 paddingParagraph"><span class="s1">A cookie is a small text file which is stored on your computer to keep
        track of what happens during your visit and to recognize the computer. A cookie only contains text, is not a
        program and cannot contain virus.</span></p>

    <p class="p1 paddingPart"><span class="s1">3.2 How to reject or delete cookies:</span></p>
    <p class="p1 paddingParagraph"><span class="s1">You can always reject the use of cookies on your computer by changing
        the settings in your web browser. Where you find the settings depends on your browser. Note, that if you reject
        cookies many functions and services may become unavailable, because these functions and services are based on the
        website remembering your choices.</span></p>
    <p class="p1 paddingParagraph"><span class="s1">Cookies which you have previously accepted can easily be deleted. If
        you use a PC/computer with a recent web browser you can delete cookies by pressing CTRL + SHIFT+ Delete. If this
        does not work or if you use a MAC, you start by finding out what browser you use and then click on the following
        link:</span></p>

    <p class="p1 paddingSection"><span class="s1"><b>4. Your rights</b></span></p>
  <p class="p1 paddingParagraph"><span class="s1">At any time, you have the right to be informed of what data we process
      concerning you, from where we have collected it and what we use it for. You may also be informed about how long we
      store your data, and who receives data concerning you, to that extent that we pass on data in Denmark and to
      foreign countries.</span></p>
  <p class="p1 paddingParagraph"><span class="s1">If you request, we can inform you about what data we process
      concerning you. The access might be limited with respect to privacy protection, business secrets and immaterial
      rights.</span></p>
  <p class="p1 paddingParagraph"><span class="s1">You can make use of your rights by contacting us. You can find our
      contact information at the top.</span></p>
  <p class="p1 paddingParagraph"><span class="s1">If you think that the personal data we process concerning you is
      imprecise, you have the right to have it corrected. In that case you must contact us and inform us what the
      imprecision is and how it can be corrected.</span></p>
  <p class="p1 paddingParagraph"><span class="s1">In some cases, we will have an obligation to delete your personal
      data. This is for instance the case if you withdraw your consent. If you think that your data is no longer
      necessary to the purpose we collected it for, you can request it to be deleted. You can also contact us if you
      believe that your data is processed in contravention of the law or other legal obligations.</span></p>
  <p class="p1 paddingParagraph"><span class="s1">You can also lodge a complaint with a supervisory authority.</span>
  </p>
  <p class="p1 paddingParagraph"><span class="s1">When you contact us with a request to have your personal data
      corrected or deleted, we examine whether the requirements are fulfilled, and if that is the case, we make the
      changes or delete as soon as possible.</span></p>
  <p class="p1 paddingParagraph"><span class="s1">You have the right to object to the processing of your personal data.
      You can also object to the processing of our passing of your data for marketing purposes. You can make use of the
      contact information at the top to object. If your objection is justified, we make sure to stop the processing of
      your personal data.</span></p>
  <p class="p1 paddingParagraph"><span class="s1">You can make use of data portability if you want your data transferred
      to another data controller or data processor.</span></p>
  <p class="p1 paddingParagraph"><span class="s1">On our own initiative, we delete your personal data, when it is no
      longer needed for the purpose we collected it for.</span></p>
  </br></br></br>
</div></template>

<script>
export default {
  name: 'Agreement',
  data() {
    return {

    }
  }
}
</script>

<style scoped>p.p0 {
  margin: 0.0px 50.0px 0.0px 50.0px;
  font: 24.0px Arial;
  color: #535353;
  -webkit-text-stroke: #535353;
  background-color: #ffffff
}

p.p1 {
  margin: 0.0px 50.0px 0.0px 50.0px;
  font: 16.0px Arial;
  color: #535353;
  -webkit-text-stroke: #000000;
  background-color: #ffffff
}

p.p2 {
  margin: 0.0px 50.0px 12.0px 50.0px;
  font: 16.0px Helvetica;
  -webkit-text-stroke: #000000
}

p.p3 {
  margin: 0.0px 50.0px 0.0px 50.0px;
  font: 14.0px Arial;
  color: #535353;
  -webkit-text-stroke: #535353
}

p.p6 {
  margin: 0.0px 50.0px 12.0px 50.0px;
  font: 16.0px Helvetica;
  color: #022140;
  -webkit-text-stroke: #022140
}

p.p8 {
  margin: 0.0px 50.0px 0.0px 50.0px;
  font: 16.0px Helvetica;
  color: #ffffff;
  -webkit-text-stroke: #ffffff
}

p.p9 {
  margin: 0.0px 50.0px 0.0px 50.0px;
  font: 16.0px Helvetica;
  color: #ffffff;
  -webkit-text-stroke: #ffffff;
  min-height: 19.0px
}

p.p10 {
  margin: 0.0px 50.0px 12.0px 50.0px;
  font: 16.0px Helvetica;
  -webkit-text-stroke: #000000;
  min-height: 19.0px
}

p.paddingParagraph {
  padding-top: 10px
}

p.paddingPart {
  padding-top: 20px;
  color: #000000;
}

p.paddingSection {
  padding-top: 45px;
  color: #000000;
}

li.li5 {
  margin: 5.0px 0.0px 0.0px 0.0px;
  font: 16.0px Arial;
  color: #535353;
  -webkit-text-stroke: #535353
}

span.s1 {
  font-kerning: none
}

span.s2 {
  font: 16.0px Helvetica;
  font-kerning: none;
  color: #022140;
  -webkit-text-stroke: 0px #022140
}

span.s3 {
  -webkit-text-stroke: 0px #000000
}

span.s4 {
  font-kerning: none;
  color: #000000;
  -webkit-text-stroke: 0px #000000
}

span.s5 {
  font-kerning: none;
  -webkit-text-stroke: 0px #022140
}

table.t1 {
  border-collapse: collapse
}

td.td1 {
  width: 225.1px;
  background-color: #08428d;
  padding: 6.0px 6.0px 6.0px 6.0px
}

td.td2 {
  width: 167.3px;
  background-color: #08428d;
  padding: 6.0px 6.0px 6.0px 6.0px
}

td.td3 {
  width: 436.2px;
  background-color: #08428d;
  padding: 6.0px 6.0px 6.0px 6.0px
}

td.td4 {
  width: 126.4px;
  background-color: #08428d;
  padding: 6.0px 6.0px 6.0px 6.0px
}

td.td5 {
  width: 225.1px;
  background-color: #022140;
  padding: 6.0px 6.0px 6.0px 6.0px
}

td.td6 {
  width: 167.3px;
  background-color: #022140;
  padding: 6.0px 6.0px 6.0px 6.0px
}

td.td7 {
  width: 436.2px;
  background-color: #022140;
  padding: 6.0px 6.0px 6.0px 6.0px
}

td.td8 {
  width: 126.4px;
  background-color: #022140;
  padding: 6.0px 6.0px 6.0px 6.0px
}

ul.ul1 {
  margin: 10.0px 50.0px 0.0px 50.0px;
  list-style-type: disc
}</style>
