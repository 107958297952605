<template>
  <div class="container">
    <el-container>
      <el-header height="none">
        <div class="header">
          <div class="login_list">
            <div class="list img"><img src="@/assets/Images/icon.png" alt=""></div>
            <div class="list" @click="toHome">
              {{ $t("main.首页") }}
            </div>
            <div class="list" @click="toDownload">
              {{ $t('main.下载中心') }}
            </div>
            <div class="list" @click="toIntroduction">
              {{ $t('main.快速入门') }}
            </div>
          </div>
          <div class="header_right">
            <div class="lang">
              <img src="@/assets/Images/white_network.png" style="height:1.04166666vw;width:1.04166666vw" alt="">
              <div>
                <div style="margin-bottom: 10px;" @click="changeZhLang">简体中文</div>
                <div @click="changeEnLang">English</div>
              </div>
            </div>
            <div class="regest" @click="toAdmin">{{ $t('main.登录') }}&nbsp;&nbsp;|&nbsp;&nbsp;{{ $t('main.注册') }}</div>
          </div>
        </div>
      </el-header>
      <el-container class="menu_container">
        <el-aside class="menu_container_menu">
          <SideBar></SideBar>
        </el-aside>
        <el-main>
          <div class="main">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
      <Footer></Footer>
    </el-container>

  </div>
</template>

<script>
import SideBar from "@/components/SideBar/index.vue"
import Footer from "@/components/Footer";

export default {
  components: {
    SideBar, Footer
  },
  name: 'Home',
  data() {
    return {
      src: "@/assets/Images/icon.png",
      // language: localStorage.getItem('lang') || this.$i18n.locale
      language: localStorage.getItem('lang') || this.$i18n.locale
    }
  },
  created() {
    localStorage.setItem("lang", this.$i18n.locale)
  },
  methods: {
    changeZhLang() {
      this.$i18n.locale = 'zh'
      localStorage.setItem("lang", "zh")
      // location.reload()
    },
    changeEnLang() {
      this.$i18n.locale = 'en'   // 设置当前语言
      localStorage.setItem("lang", "en")
      // location.reload()
    },
    toHome() {
      this.$router.push("/home/homePage")
    },
    toDownload() {
      this.$router.push("/home/download")
    },
    toIntroduction() {
      // this.$router.push("/home/introduction")
    },
    toVideo() {
      this.$router.push("/home/video")
    },
    toPrivacy() {
      this.$router.push({ name: 'Privacy' })
    },
    toAdmin() {
      window.open('https://console.joyinn.net/')
    }
  }
}

</script>

<style lang="scss" scoped>
.container {
  margin: 0;
  padding: 0;
  width: 100vw;
  // height: 56.25vw;

  .el-header {
    padding: 0;

  }

  .el-footer {
    padding: 0;
  }

  .el-container {
    height: 100%;

    .menu_container {
      // height: calc(56.25vw - 5.20833333vw - 10.8645833vw);
      min-height: calc(45.1520833vw - 100px - 4px);
    }
  }

  .el-main {
    padding: 28px 0 0 120px;
  }

  .header {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: space-between;
    background-color: #FD8137;
    color: white;

    .login_list {
      display: flex;
      height: 100%;

      .img {
        margin-right: 100px;
        margin-left: 24px;

        img {
          height: 50px;
          width: 50px;
        }
      }

      .list {
        height: 100%;
        // margin-top: 26px;
        margin-right: 80px;
        color: rgba(250, 250, 250, 1);
        font-size: 18px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .header_right {
      display: flex;
      align-items: center;
      color: white;

      .lang {
        display: flex;
        height: 50px;
        // align-items: center;
        color: rgba(16, 16, 16, 1);
        font-size: 14px;
        margin-top: 10px;
        margin-right: 42px;
        text-align: center;

        img {
          margin-right: 8px;
        }

        div {
          height: 20px;
          line-height: 20px;
          color: white;
          cursor: pointer;
        }
      }

      .regest {
        color: rgba(16, 16, 16, 1);
        font-size: 14px;
        margin-right: 56px;
        color: white;
        cursor: pointer;
      }
    }
  }

  .menu_container_menu {
    width: 300px !important;
  }
}
</style>