<template>
  <router-view></router-view>
</template>

<script>
export default {
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
</style>
